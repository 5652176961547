/* eslint-disable import/no-unresolved */
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Anchor from "../components/elements/Anchor";
import Layout from "../components/common/Layout";
import Seo from "../components/meta/Seo";

const Homepage = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  const heroImage = page.data.homepage_image.gatsbyImageData;
  const heroImageMobile = page.data.homepage_image_mobile.gatsbyImageData;
  const heroImageMedium = page.data.homepage_image_mobile.gatsbyImageData;

  return (
    <>
      <Seo page={page} />
      <Layout bookNowUrl={page.data.book_now_url}>
        <div className="hero-image position-relative">
          <GatsbyImage
            image={heroImage}
            alt={heroImage.alt || "Hero Image"}
            className="h-100 w-100 d-lg-block d-none"
          />
          <GatsbyImage
            image={heroImageMedium}
            alt={heroImageMedium.alt || "Hero Image"}
            className="h-100 w-100 d-md-block d-lg-none"
          />
          <GatsbyImage
            image={heroImageMobile}
            alt={heroImageMobile.alt || "Hero Image"}
            className="h-100 w-100 d-none d-md-none"
          />
          {/* <div className="image-map-container d-none d-xl-flex">
            <div className="col-2 col-xxl-2 align-self-end">
              <OverlayTrigger
                key="top"
                trigger="click"
                placement="top"
                overlay={
                  <Popover id="popover-positioned-top">
                    <Popover.Body>
                      <Anchor
                        href={page.data.villa_1_link.url}
                        className="nav-link"
                      >
                        <h5 className="mb-0">{page.data.villa_1}</h5>
                      </Anchor>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button
                  className="image-map-icon d-block me-xl-2 me-xxl-5 last-btn"
                  aria-label="open popover"
                >
                  <svg
                    width="39"
                    height="40"
                    viewBox="0 0 39 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="19.5" cy="20.3316" r="19.5" fill="white" />
                    <path
                      d="M32.2 19.4849V21.1783H20.3467V33.8783H18.6533V21.1783H6.79999V19.4849H18.6533V6.78491H20.3467V19.4849H32.2Z"
                      fill="#8000FF"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
            <div className="col-2 col-xxl-2 align-self-end">
              <OverlayTrigger
                key="top"
                trigger="click"
                placement="top"
                overlay={
                  <Popover id="popover-positioned-top">
                    <Popover.Body>
                      <Anchor
                        href={page.data.villa_2_link.url}
                        className="nav-link"
                      >
                        <h5 className="mb-0">{page.data.villa_2}</h5>
                      </Anchor>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button
                  className="image-map-icon d-block me-xl-2 me-xxl-5 last-btn"
                  aria-label="open popover"
                >
                  <svg
                    width="39"
                    height="40"
                    viewBox="0 0 39 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="19.5" cy="20.3316" r="19.5" fill="white" />
                    <path
                      d="M32.2 19.4849V21.1783H20.3467V33.8783H18.6533V21.1783H6.79999V19.4849H18.6533V6.78491H20.3467V19.4849H32.2Z"
                      fill="#8000FF"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
            <div className="col-2 align-self-end">
              <div className="d-flex justify-content-end justify-content-xl-between">
                <div>
                  <OverlayTrigger
                    key="top"
                    trigger="click"
                    placement="top"
                    overlay={
                      <Popover id="popover-positioned-top">
                        <Popover.Body>
                          <Anchor
                            href={page.data.villa_3_link.url}
                            className="nav-link"
                          >
                            <h5 className="mb-0">{page.data.villa_3}</h5>
                          </Anchor>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      className="image-map-icon d-block me-xl-2 me-xxl-5 last-btn"
                      aria-label="open popover"
                    >
                      <svg
                        width="39"
                        height="40"
                        viewBox="0 0 39 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="19.5" cy="20.3316" r="19.5" fill="white" />
                        <path
                          d="M32.2 19.4849V21.1783H20.3467V33.8783H18.6533V21.1783H6.79999V19.4849H18.6533V6.78491H20.3467V19.4849H32.2Z"
                          fill="#8000FF"
                        />
                      </svg>
                    </Button>
                  </OverlayTrigger>
                </div>
                <div>
                  <OverlayTrigger
                    key="top"
                    trigger="click"
                    placement="top"
                    overlay={
                      <Popover id="popover-positioned-top">
                        <Popover.Body>
                          <Anchor
                            href={page.data.villa_4_link.url}
                            className="nav-link"
                          >
                            <h5 className="mb-0">{page.data.villa_4}</h5>
                          </Anchor>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      className="image-map-icon d-block me-xl-2 me-xxl-5 last-btn"
                      aria-label="open popover"
                    >
                      <svg
                        width="39"
                        height="40"
                        viewBox="0 0 39 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="19.5" cy="20.3316" r="19.5" fill="white" />
                        <path
                          d="M32.2 19.4849V21.1783H20.3467V33.8783H18.6533V21.1783H6.79999V19.4849H18.6533V6.78491H20.3467V19.4849H32.2Z"
                          fill="#8000FF"
                        />
                      </svg>
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="col-1 col-xl-2 align-self-end" />
          </div>
          <div className="image-map-mobile d-block d-xl-none">
            <OverlayTrigger
              key="bottom"
              trigger="click"
              placement="bottom"
              className="image-map-mobile"
              overlay={
                <Popover id="popover-positioned-bottom">
                  <Popover.Body>
                    <Anchor
                      href={page.data.villa_1_link.url}
                      className="nav-link"
                    >
                      <h5 className="m-2">{page.data.villa_1}</h5>
                    </Anchor>
                    <Anchor
                      href={page.data.villa_2_link.url}
                      className="nav-link"
                    >
                      <h5 className="m-2">{page.data.villa_2}</h5>
                    </Anchor>
                    <Anchor
                      href={page.data.villa_3_link.url}
                      className="nav-link"
                    >
                      <h5 className="m-2">{page.data.villa_3}</h5>
                    </Anchor>
                    <Anchor
                      href={page.data.villa_4_link.url}
                      className="nav-link"
                    >
                      <h5 className="m-2">{page.data.villa_4}</h5>
                    </Anchor>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button
                // variant="secondary"
                className="image-map-icon d-block me-xl-2 me-xxl-5 last-btn"
                aria-label="open popover"
              >
                <svg
                  width="39"
                  height="40"
                  viewBox="0 0 39 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="19.5" cy="20.3316" r="19.5" fill="white" />
                  <path
                    d="M32.2 19.4849V21.1783H20.3467V33.8783H18.6533V21.1783H6.79999V19.4849H18.6533V6.78491H20.3467V19.4849H32.2Z"
                    fill="#8000FF"
                  />
                </svg>
              </Button>
            </OverlayTrigger>
          </div> */}
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query homepageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicHomepage {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        meta_description
        meta_title
        homepage_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        homepage_image_mid_size {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        homepage_image_mobile {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        villa_1
        villa_1_link {
          id
          url
          target
        }
        villa_2
        villa_2_link {
          id
          target
          url
        }
        villa_3
        villa_3_link {
          id
          target
          url
        }
        villa_4
        villa_4_link {
          id
          url
          target
        }
        meta_image {
          alt
          dimensions {
            height
            width
          }
        }
      }
    }
  }
`;

export default Homepage;
